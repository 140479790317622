import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { DatePickerInput } from "@mantine/dates";

const DatePicker = () => {
    const [selectedDate, setSelectedDate] = useOutletContext()[2];
    const [date, setDate] = useState(selectedDate);

    useEffect(() => {
        setDate(selectedDate);
    }, [selectedDate]);

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    return (
        <DatePickerInput
            value={date}
            onChange={handleDateChange}
            placeholder="Pick a date"
            styles={() => ({
                root: {
                    maxWidth: "300px",
                    margin: "10px auto",
                },
                input: {
                    backgroundColor: "#595959",
                    color: "#fff",
                    border: "1px solid #BEBEBE",
                },
                dropdown: {
                    border: "1px solid #fff",
                    borderRadius: "4px",
                },
            })}
        />
    );
};

export default DatePicker;
