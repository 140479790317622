// hooks/useCreateCourse.js
import { useState, useEffect, useRef, useContext } from "react";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
    getInitialValues,
    validateDueDate,
    calculateTotalWorth,
} from "../utils/courseUtils";
import { formatDateOrNull } from "../utils/generalUtils";
import { showCustomNotification } from "../utils/notification";
import { CourseContext } from "../contexts/courseContext";

// Import semester options from courseConstants.js
import {
    ASSIGNMENT,
    EXAM,
    SemesterNameOptions,
} from "../constants/courseConstants";
import { randomId } from "@mantine/hooks";
import courseService from "../api/courseService";
import fileService from "../api/fileService";

export function useCreateCourse(syllabusFile = null) {
    const [loaderVisible, handlers] = useDisclosure(false);
    const [totalWorth, setTotalWorth] = useState(0);
    // Store the fileId of the uploaded syllabus to associate it with the course when created.
    const [syllabusFileId, setSyllabusFileId] = useState(null);
    // This ref prevents processing the syllabus multiple times due to re-renders
    const syllabusProcessedRef = useRef(false);
    const updateSidebarCourses = useContext(CourseContext);

    useEffect(() => {
        if (syllabusFile && !syllabusProcessedRef.current) {
            handleSyllabusUpload(syllabusFile);
            syllabusProcessedRef.current = true;
        }
    }, [syllabusFile]);

    const form = useForm({
        initialValues: {
            title: "",
            department: "",
            code: "",
            year: null,
            semester: "",
            examTasks: [],
            assignmentTasks: [],
            is_public: false,
        },
        validate: {
            title: (value) =>
                value.length >= 3
                    ? null
                    : "Title must be at least 3 characters long",
            department: (value) =>
                value.length >= 2
                    ? null
                    : "Department must be at least 2 characters long",
            code: (value) => (value >= 0 ? null : "code be a negative number"),
            year: (value) => (value >= 0 ? null : "year be a negative number"),
            semester: (value) =>
                SemesterNameOptions.has(value)
                    ? null
                    : "Invalid semester. Please select a valid semester.",
            examTasks: {
                title: (value) =>
                    value.length >= 3
                        ? null
                        : "Task title must be at least 3 characters long",
                dueDate: (value, values) =>
                    validateDueDate(value, values)
                        ? null
                        : "Due date must be within the semester",
                worth: (value) =>
                    value > 0 && value < 100
                        ? null
                        : "worth must be between 0 and 100",
            },
            assignmentTasks: {
                title: (value) =>
                    value.length >= 3
                        ? null
                        : "Task title must be at least 3 characters long",
                dueDate: (value, values) =>
                    validateDueDate(value, values)
                        ? null
                        : "Due date must be within the semester",
                worth: (value) =>
                    value >= 0 && value <= 100
                        ? null
                        : "worth must be between 0 and 100 (inclusive)",
            },
        },
        onValuesChange: (values) => {
            setTotalWorth(
                calculateTotalWorth(values.examTasks, values.assignmentTasks),
            );
        },
    });

    const handleSubmit = async (values) => {
        if (totalWorth !== 100) {
            showCustomNotification({
                title: "Invalid Total Worth",
                message: "Total worth of tasks must equal 100%",
                color: "orange",
            });
            return;
        }

        handlers.open();

        try {
            const dataToSend = {
                course: {
                    title: values.title,
                    dept: values.department,
                    code: values.code,
                    year: values.year.getFullYear(),
                    semester: SemesterNameOptions.get(values.semester),
                    categories: [],
                    is_public: values.is_public,
                },
                tasks: [
                    ...values.examTasks.map((task) => ({
                        ...task,
                        due_date: formatDateOrNull(task.dueDate),
                        categories: [EXAM],
                    })),
                    ...values.assignmentTasks.map((task) => ({
                        ...task,
                        due_date: formatDateOrNull(task.dueDate),
                        categories: [ASSIGNMENT],
                    })),
                ],
                syllabus_file_id: syllabusFileId,
            };
            console.log(dataToSend);
            await courseService
                .createCourse(dataToSend)
                .then(updateSidebarCourses);
            showCustomNotification({
                title: "Course Created",
                message: "Course created successfully",
                color: "green",
            });
            form.reset();
        } catch (error) {
            showCustomNotification({
                title: "Course Creation Failed",
                message:
                    error.response?.data?.message ||
                    "An error occurred while creating the course",
                color: "red",
            });
            console.error("Error creating course:", error);
        } finally {
            handlers.close();
        }
    };

    const handleSyllabusUpload = async (file) => {
        if (!file) {
            showCustomNotification({
                title: "File Selection Required",
                message: "Please select a file to upload.",
                color: "red",
            });
            return;
        }

        handlers.open();

        try {
            // Upload the file to the server
            const uploadRes = await fileService.uploadFile(file, "syllabus");

            // Parse the syllabus
            const createRes = await courseService.parseSyllabus(uploadRes.id);
            setSyllabusFileId(uploadRes.id);
            const { course } = createRes.result;
            formatParsedSyllabus(course);
            showCustomNotification({
                title: "Syllabus Parsed",
                message: "Syllabus parsed successfully!",
                color: "green",
            });
        } catch (error) {
            showCustomNotification({
                title: "Syllabus Parsing Failed",
                message:
                    error.response?.data?.message ||
                    "An error occurred while parsing the syllabus",
                color: "red",
            });
            console.error("Error:", error);
        } finally {
            handlers.close();
        }
    };

    const formatParsedSyllabus = (courseData) => {
        // Clean the data
        for (const key in courseData) {
            if (key === "tasks") continue;
            const value = courseData[key];
            if (value === "unknown" || value === "" || value === "null") {
                courseData[key] = null;
            }
        }

        const { title, department, code, year, semester, tasks } = courseData;
        const examTasks = tasks.filter((task) => task.type === EXAM);
        const assignmentTasks = tasks.filter(
            (task) => task.type === ASSIGNMENT,
        );

        form.setValues({
            title,
            department,
            code,
            year: year ? new Date(year, 0, 1) : null,
            semester,
            examTasks: examTasks.map((task) => ({
                ...task,
                dueDate: task.dueDate ? new Date(task.dueDate) : null,
                key: randomId(),
            })),
            assignmentTasks: assignmentTasks.map((task) => ({
                ...task,
                dueDate: task.dueDate ? new Date(task.dueDate) : null,
                key: randomId(),
            })),
            is_public: false,
        });
    };

    const fillDummyValues = async () => {
        handlers.open();

        // wait for one second to simulate a network request
        await new Promise((resolve) => setTimeout(resolve, 1000));

        try {
            const values = getInitialValues();
            form.setValues(values);
        } catch (error) {
            console.error("Error filling dummy values:", error);
            showCustomNotification({
                title: "Dummy Values Failed",
                message: "Failed to fill dummy values. Please try again.",
                color: "red",
            });
        } finally {
            handlers.close();
        }
    };

    const clearForm = () => {
        form.reset();
    };

    return {
        form,
        totalWorth,
        handleSubmit,
        handleSyllabusUpload,
        fillDummyValues,
        clearForm,
        loaderVisible,
    };
}
