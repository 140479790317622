export function getTodaysDate() {
    const today = new Date();
    return formatDateOrNull(today);
}

export function formatDateOrNull(date) {
    if (!date) return null;

    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
}

export function validateUrl(url) {
    // This validates that the URL start with "http://" or "https://"
    return url.match(/https?:\/\/.+/);
}

/**
 * Formats a decimal number as a percentage string.
 *
 * @param {number} decimal - The decimal number to format as a percentage.
 * @return {string} The formatted percentage string.
 *
 * @example
 * formatAsPercentage(0.75); // Returns "75.00%"
 * formatAsPercentage(0.5);  // Returns "50.00%"
 * formatAsPercentage(1.25); // Returns "125.00%"
 */
export function formatAsPercentage(decimal) {
    if (decimal === null || decimal === undefined) {
        return "";
    }
    return (decimal * 100).toFixed(2) + "%";
}
